import {
  createAnimation, IonButton,
  IonCard,
  IonCardContent, IonCardHeader,
  IonCardSubtitle,
  IonCardTitle, IonContent,
  IonIcon, IonInput,
  IonItem, IonList, IonPage
} from '@ionic/react';
import { lockOpen } from 'ionicons/icons';
import React, { FormEvent, useContext, useRef, useState } from 'react';
import { AppContext } from '../State';
import './Authorization.css';
import { keys } from './keys.json';

const Authorization: React.FC = () => {
  const [code, setCode] = useState<string>('');
  const { dispatch } = useContext(AppContext);
  const authorizeButton = useRef<HTMLIonButtonElement>(null);

  const authorize = (e: FormEvent) => {
    e.preventDefault();
    const acceptableKeys = keys;
    if (acceptableKeys.includes(code)) {
      dispatch({
        type: 'authorize',
      });
    } else {
      createAnimation()
        .addElement(authorizeButton.current!)
        .duration(100)
        .iterations(1)
        .keyframes([
          { offset: 0, transform: 'translateX(0px)' },
          { offset: 0.25, transform: 'translateX(-10px)' },
          { offset: 0.5, transform: 'translateX(0px)' },
          { offset: 0.75, transform: 'translateX(10px)' },
          { offset: 1, transform: 'translateX(0px)' },
        ])
        .play();
    }
  };

  return (
    <IonPage>
      <IonContent>
        <div className="authorization">
          <IonCard className="authorizationCard">
            <IonCardHeader>
              <IonCardTitle>Authorization</IonCardTitle>
              <IonCardSubtitle>
                Please enter the code you have received with your order.
              </IonCardSubtitle>
            </IonCardHeader>

            <IonCardContent>
              <form onSubmit={authorize}>
                <IonList>
                  <IonItem>
                    <IonInput
                      value={code}
                      onIonChange={(e) =>
                        setCode(e.detail.value!.toUpperCase())
                      }
                      enterkeyhint="go"
                      pattern="[A-Z0-9]+-[A-Z0-9]+-[A-Z0-9]+-[A-Z0-9]+"
                      minlength={19}
                      maxlength={19}
                      required
                    ></IonInput>
                  </IonItem>
                </IonList>
                <IonButton
                  type="submit"
                  expand="block"
                  fill="outline"
                  color="primary"
                  ref={authorizeButton}
                >
                  <IonIcon slot="start" icon={lockOpen} />
                  Authorize
                </IonButton>
              </form>
            </IonCardContent>
          </IonCard>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Authorization;
