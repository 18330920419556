import React from 'react';
import {
  IonContent,
  IonPage,
} from '@ionic/react';

import WheelOfLifeGameBoard from '../components/WheelOfLifeGameBoard';

const WheelOfLife: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <WheelOfLifeGameBoard />
      </IonContent>
    </IonPage>
  );
};

export default WheelOfLife;
