import React, { useContext } from 'react';
import { Route } from 'react-router-dom';
import { IonApp } from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { AppContext } from './State';

import Authorization from './pages/Authorization';
import MainContent from './components/MainContent';

const App: React.FC = () => {
  const { state } = useContext(AppContext);

  return (
    <IonApp>
      <IonReactRouter>
        <Route
          path="/"
          component={state.isAuthorized ? MainContent : Authorization}
        />
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
