import React, { useRef } from 'react';
import {
  IonContent,
  IonPage,
  IonFab,
  IonFabButton,
  IonIcon,
} from '@ionic/react';

import TreeGameBoard from '../components/TreeGameBoard';

const Tree: React.FC = () => {
  const treeGameBoard = useRef<any>(null);

  return (
    <IonPage>
      <IonContent>
        <IonFab vertical="top" horizontal="center" slot="fixed">
          <IonFabButton
            color="light"
            onClick={() => treeGameBoard.current.rollDie()}
          >
            <IonIcon src="assets/images/icons/dice.svg" />
          </IonFabButton>
        </IonFab>
        <TreeGameBoard ref={treeGameBoard} />
      </IonContent>
    </IonPage>
  );
};

export default Tree;
