export default class WheelOfLifeCategory {
  name: string;
  current: number;
  desired: number;
  constructor(name: string) {
    this.name = name;
    this.current = 0;
    this.desired = 0;
  }
}
