import React from 'react';
import {
  IonContent,
  IonPage,
} from '@ionic/react';

import RootsGameBoard from '../components/RootsGameBoard';

const Roots: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <RootsGameBoard />
      </IonContent>
    </IonPage>
  );
};

export default Roots;
