import {
  IonInput, IonItem,

  IonItemDivider,
  IonItemGroup, IonLabel, IonRange
} from '@ionic/react';
import React from 'react';
import WheelOfLifeCategory from '../models/WheelOfLifeCategory';


interface ContainerProps {
  category: WheelOfLifeCategory;
  index: number;
  onEditCurrent: Function;
  onEditDesired: Function;
}

const WheelOfLifeCategoryItem: React.FC<ContainerProps> = ({
  category,
  index,
  onEditCurrent,
  onEditDesired,
}) => {

  return (
    <IonItemGroup>
      <IonItemDivider>
        <IonLabel><IonInput value={category.name} onIonChange={(e) => onEditCurrent(index, e.detail.value, 'name')}></IonInput></IonLabel>
      </IonItemDivider>
      <IonItem>
        <IonLabel>Current</IonLabel>
        <IonRange
          min={0}
          max={10}
          step={1}
          snaps
          pin
          onIonChange={(e) => onEditCurrent(index, e.detail.value)}
        >
          <IonLabel slot="start">0</IonLabel>
          <IonLabel slot="end">10</IonLabel>
        </IonRange>
      </IonItem>
      <IonItem>
        <IonLabel>Desired</IonLabel>
        <IonRange
          min={0}
          max={10}
          step={1}
          snaps
          pin
          onIonChange={(e) => onEditDesired(index, e.detail.value)}
        >
          <IonLabel slot="start">0</IonLabel>
          <IonLabel slot="end">10</IonLabel>
        </IonRange>
      </IonItem>
    </IonItemGroup>
  );
};

export default WheelOfLifeCategoryItem;
