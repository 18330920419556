import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import './Credits.css';

const Tab1: React.FC = () => {
  return (
    <IonPage>
      <IonContent>
        <div className="credits">
          <strong>Icons made by</strong>
          <p>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.flaticon.com/authors/freepik"
              title="Freepik"
            >
              Freepik
            </a>
            ,&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://smashicons.com"
              title="Smashicons"
            >
              Smashicons
            </a>
            &nbsp;and&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.flaticon.com/authors/pixel-perfect"
              title="Pixel perfect"
            >
              Pixel perfect
            </a>
          </p>
          <p>
            from&nbsp;
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.flaticon.com/"
              title="Flaticon"
            >
              Flaticon
            </a>
          </p>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Tab1;
