import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle, IonCol, IonGrid,


  IonList, IonRow
} from '@ionic/react';
import React, { useState } from 'react';
import { Radar } from 'react-chartjs-2';
import WheelOfLifeCategory from '../models/WheelOfLifeCategory';
import WheelOfLifeCategoryItem from './WheelOfLifeCategoryItem';
import './WheelOfLifeGameBoard.css';



const options = {
  scale: {
    ticks: {
      suggestedMin: 0,
      suggestedMax: 10,
    },
  },
  legend: {
    display: false,
  },
  tooltips: {
    enabled: false,
  },
};

const WheelOfLifeGameBoard: React.FC = () => {
  const [categories, setCategories] = useState<WheelOfLifeCategory[]>([
    new WheelOfLifeCategory('Career'),
    new WheelOfLifeCategory('Finance'),
    new WheelOfLifeCategory('Personal Growth'),
    new WheelOfLifeCategory('Health'),
    new WheelOfLifeCategory('Family'),
    new WheelOfLifeCategory('Relationships'),
    new WheelOfLifeCategory('Social Life'),
    new WheelOfLifeCategory('Attitude'),
  ]);

  const [currentData, setCurrentData] = useState<Object>({
    labels: categories.map((category) => category.name),
    datasets: [
      {
        backgroundColor: 'rgba(121,206,201,0.2)',
        borderColor: 'rgba(121,206,201,1)',
        pointBackgroundColor: 'rgba(121,206,201,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(121,206,201,1)',
        data: categories.map((category) => category.current),
      },
    ],
  });

  const [desiredData, setDesiredData] = useState<Object>({
    labels: categories.map((category) => category.name),
    datasets: [
      {
        backgroundColor: 'rgba(121,206,201,0.2)',
        borderColor: 'rgba(121,206,201,1)',
        pointBackgroundColor: 'rgba(121,206,201,1)',
        pointBorderColor: '#fff',
        pointHoverBackgroundColor: '#fff',
        pointHoverBorderColor: 'rgba(121,206,201,1)',
        data: categories.map((category) => category.desired),
      },
    ],
  });

  function editCategoryCurrent(index: number, value: any, property: string) {
    const newCategories = [...categories];

    if (property) {
      newCategories[index].name = value;
    } else {
      newCategories[index].current = value;
    }

    setCategories(newCategories);
  }

  function editCategoryDesired(index: number, value: any, property: string) {
    const newCategories = [...categories];
    
    if (property) {
      newCategories[index].name = value;
    } else {
      newCategories[index].desired = value;
    }
    
    setCategories(newCategories);
  }

  function generateWheelsOfLife() {
    const newCurrentData: any = { ...currentData };
    newCurrentData.datasets[0].data = categories.map(
      (category) => category.current
    );
    newCurrentData.labels = categories.map((category) => category.name)
    
    setCurrentData(newCurrentData);

    const newDesiredData: any = { ...desiredData };
    newDesiredData.datasets[0].data = categories.map(
      (category) => category.desired
    );
    newDesiredData.labels = categories.map((category) => category.name)
    setDesiredData(newDesiredData);
  }

  return (
    <div>
      <IonGrid>
        <IonRow>
          <IonCol size="6">
            <IonCard>
              <IonCardContent>
                <IonList>
                  {categories.map((category, index) => (
                    <WheelOfLifeCategoryItem
                      key={index}
                      category={category}
                      index={index}
                      onEditCurrent={editCategoryCurrent}
                      onEditDesired={editCategoryDesired}
                    />
                  ))}
                </IonList>
              </IonCardContent>
            </IonCard>
          </IonCol>
          <IonCol size="6">
            <IonCard className="wheelOfLifeGameBoardCardWhite">
              <IonCardContent className="ion-text-center">
                <IonButton
                  color="primary"
                  fill="outline"
                  onClick={generateWheelsOfLife}
                >
                  Generate my Wheels of Life
                </IonButton>
                <IonCardHeader>
                  <IonCardTitle color="primary">Current</IonCardTitle>
                </IonCardHeader>
                <Radar data={currentData} options={options} />
                <IonCardHeader>
                  <IonCardTitle color="primary">Desired</IonCardTitle>
                </IonCardHeader>
                <Radar data={desiredData} options={options} />
              </IonCardContent>
            </IonCard>
          </IonCol>
        </IonRow>
      </IonGrid>
    </div>
  );
};

export default WheelOfLifeGameBoard;
