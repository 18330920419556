import React, { useRef, useState } from 'react';
import { useIonViewWillLeave, useIonViewDidEnter } from '@ionic/react';
import './RootsGameBoard.css';

import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';
import { Vector2d } from 'konva/types/types';
import { ResizeObserver } from 'resize-observer';
import { KonvaEventObject } from 'konva/types/Node';

const RootsGameBoard: React.FC = () => {
  const [boardWidth, setBoardWidth] = useState<number>(1587);
  const [boardHeight, setBoardHeight] = useState<number>(1122);
  const [boardScale, setBoardScale] = useState<Vector2d>({ x: 1, y: 1 });
  const [pawns, setPawns] = useState<any[]>([]);

  const boardContainer = useRef<HTMLDivElement>(null);
  const boardLayer = useRef<any>(null);

  const [boardImage] = useImage('assets/images/boards/roots.jpg');
  const [pawnImage] = useImage('assets/images/pawns/heart-coin.png');

  let resizeObserver: ResizeObserver | null = null;

  useIonViewDidEnter(() => {
    resizeObserver = new ResizeObserver(() => {
      checkSize();
    });

    resizeObserver.observe(boardContainer.current!);
  });

  useIonViewWillLeave(() => {
    if (resizeObserver != null) {
      resizeObserver.disconnect();
    }
  });

  function checkSize() {
    const height = boardContainer.current!.offsetHeight;
    const scale = height / boardHeight;

    setBoardWidth(boardWidth * scale);
    setBoardHeight(boardHeight * scale);
    setBoardScale({ x: scale, y: scale });
  }

  function addPawn(event: KonvaEventObject<MouseEvent>) {
    const transform = boardLayer.current.getAbsoluteTransform().copy();
    transform.invert();
    const pos = event.target.getStage()!.getPointerPosition();
    const circlePos = transform.point(pos);
    setPawns((pawns) => [...pawns, circlePos]);
  }

  function removePawn(event: KonvaEventObject<MouseEvent>) {
    const x = event.target.getPosition().x;
    const y = event.target.getPosition().y;
    setPawns((pawns) => pawns.filter((pawn) => pawn.x !== x && pawn.y !== y));
  }

  return (
    <div ref={boardContainer} className="rootsGameBoardContainer">
      <Stage width={boardWidth} height={boardHeight} scale={boardScale}>
        <Layer ref={boardLayer}>
          <Image x={0} y={0} image={boardImage} onClick={addPawn} />
          {pawns.map((pawn, key) => {
            return (
              <Image
                x={pawn.x}
                y={pawn.y}
                offsetX={pawnImage!.width / 2}
                offsetY={pawnImage!.height / 2}
                image={pawnImage}
                key={key}
                onClick={removePawn}
              />
            );
          })}
        </Layer>
      </Stage>
    </div>
  );
};

export default RootsGameBoard;
