import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import {
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { heart } from 'ionicons/icons';

import Tree from '../pages/Tree';
import Roots from '../pages/Roots';
import WheelOfLife from '../pages/WheelOfLife';
import Credits from '../pages/Credits';

const MainContent: React.FC = () => {
  return (
    <IonTabs>
      <IonRouterOutlet>
        <Route path="/tree" component={Tree} exact />
        <Route path="/roots" component={Roots} exact />
        <Route path="/wheel-of-life" component={WheelOfLife} exact />
        <Route path="/credits" component={Credits} exact />
        <Route path="/" render={() => <Redirect to="/tree" />} exact />
      </IonRouterOutlet>
      <IonTabBar slot="bottom">
        <IonTabButton tab="tree" href="/tree">
          <IonIcon src="/assets/images/icons/treehouse.svg" />
          <IonLabel>Tree</IonLabel>
        </IonTabButton>
        <IonTabButton tab="roots" href="/roots">
          <IonIcon src="/assets/images/icons/tree-roots.svg" />
          <IonLabel>Roots</IonLabel>
        </IonTabButton>
        <IonTabButton tab="wheelOfLife" href="/wheel-of-life">
          <IonIcon src="/assets/images/icons/wheel-of-life.svg" />
          <IonLabel>Wheel of Life</IonLabel>
        </IonTabButton>
        <IonTabButton tab="credits" href="/credits">
          <IonIcon icon={heart} />
          <IonLabel>Credits</IonLabel>
        </IonTabButton>
      </IonTabBar>
    </IonTabs>
  );
};

export default MainContent;
