import React, {
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import { useIonViewWillLeave, useIonViewDidEnter } from '@ionic/react';
import './TreeGameBoard.css';

import Konva from 'konva';
import { Stage, Layer, Image } from 'react-konva';
import useImage from 'use-image';
import rollADie from 'roll-a-die';
import { Vector2d } from 'konva/types/types';
import { ResizeObserver } from 'resize-observer';

const TreeGameBoard = forwardRef((props, ref) => {
  useImperativeHandle(ref, () => ({
    rollDie() {
      const element = dieContainer.current;
      const numberOfDice = 1;
      const options = {
        element,
        numberOfDice,
        callback: console.log,
      };
      rollADie(options);
    },
  }));

  const [boardWidth, setBoardWidth] = useState<number>(793);
  const [boardHeight, setBoardHeight] = useState<number>(1122);
  const [boardScale, setBoardScale] = useState<Vector2d>({ x: 1, y: 1 });

  const boardContainer = useRef<HTMLDivElement>(null);
  const dieContainer = useRef<HTMLDivElement>(null);

  const [boardImage] = useImage('assets/images/boards/tree.jpg');
  const [boardPawn] = useImage('assets/images/pawns/chess-piece.png');

  let resizeObserver: ResizeObserver | null = null;

  useIonViewDidEnter(() => {
    resizeObserver = new ResizeObserver(() => {
      checkSize();
    });

    resizeObserver.observe(boardContainer.current!);
  });

  useIonViewWillLeave(() => {
    if (resizeObserver != null) {
      resizeObserver.disconnect();
    }
  });

  function checkSize() {
    const height = boardContainer.current!.offsetHeight;
    const scale = height / boardHeight;

    setBoardWidth(boardWidth * scale);
    setBoardHeight(boardHeight * scale);
    setBoardScale({ x: scale, y: scale });
  }

  function handleDragStart(e: any) {
    e.target.setAttrs({
      shadowOffset: {
        x: 15,
        y: 15,
      },
      scaleX: 1.1,
      scaleY: 1.1,
    });
  }

  function handleDragEnd(e: any) {
    e.target.to({
      duration: 0.5,
      easing: Konva.Easings.ElasticEaseOut,
      scaleX: 1,
      scaleY: 1,
      shadowOffsetX: 5,
      shadowOffsetY: 5,
    });
  }

  return (
    <div ref={boardContainer} className="treeGameBoardContainer">
      <Stage width={boardWidth} height={boardHeight} scale={boardScale}>
        <Layer>
          <Image x={0} y={0} image={boardImage} />
          <Image
            x={65}
            y={900}
            image={boardPawn}
            draggable
            shadowColor="black"
            shadowBlur={10}
            shadowOpacity={0.6}
            onDragStart={handleDragStart}
            onDragEnd={handleDragEnd}
            onMouseEnter={(e) => {
              const container = e.target.getStage()!.container();
              container.style.cursor = 'pointer';
            }}
            onMouseLeave={(e) => {
              const container = e.target.getStage()!.container();
              container.style.cursor = 'default';
            }}
          />
        </Layer>
      </Stage>
      <div ref={dieContainer} className="treeGameBoardDieContainer" />
    </div>
  );
});

export default TreeGameBoard;
